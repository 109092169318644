<template>
  <div id="home">
    <NavBar :message="status"></NavBar>
    <header>
      <p class="header_p1">阅图智能</p>
      <p class="header_p2">阅见世界 图观未来</p>
    </header>
    <main>
      <div class="basic-info">
        <p class="description">
          阅图智能创立于2020年，旨在通过AI技术为全<br />
          球港口提供全面智慧化解决方案，不断提高港口<br />生产效率和安全管理水平。<br />
        </p>
        <div class="details">
          <ul class="details-list">
            <li class="details-item" @click="toguid('/about', 'profile')">
              <a>
                <p>公司简介</p>
                <img
                  src="../../assets/images/companyProfile.png"
                  alt="公司简介"
                />
              </a>
            </li>
            <li class="details-item" @click="toguid('/about', 'qualification')">
              <a>
                <p>企业文化</p>
                <img
                  src="../../assets/images/enterpriseCulture.png"
                  alt="企业文化"
                />
              </a>
            </li>
            <li class="details-item" @click="toguid('/about', 'address')">
              <a>
                <p>办公地址</p>
                <img
                  src="../../assets/images/businessAddress.png"
                  alt="办公地点"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="link">
        <div class="link-inner">
          <ul class="link-tab">
            <li
              class="link-tab-item tab-item-1"
              :class="tabActiveClass(0)"
              @mouseenter="setActiveTab(0)"
            >
              <a @click="toguid('/solution', 'vision')">
                <div class="inner" style="top: 60%">
                  <div class="content">
                    <h3>智慧远控解决方案</h3>
                    <p>AI赋能设备远程</p>
                  </div>
                  <i></i>
                </div>
              </a>
            </li>
            <li
              class="link-tab-item tab-item-2"
              :class="tabActiveClass(1)"
              @mouseenter="setActiveTab(1)"
            >
              <a @click="toguid('/solution', 'tally')">
                <div class="inner">
                  <div class="content">
                    <h3>智能理货系统解决方案</h3>
                    <p>AI赋能物流管理</p>
                  </div>
                  <i></i>
                </div>
              </a>
            </li>
            <li
              class="link-tab-item tab-item-3"
              :class="tabActiveClass(2)"
              @mouseenter="setActiveTab(2)"
            >
              <a @click="toguid('/solution', 'court')">
                <div class="inner" style="top: 40%">
                  <div class="content">
                    <h3>智慧场区管理</h3>
                    <p>AI赋能场区安全监管</p>
                  </div>
                  <i></i>
                </div>
              </a>
            </li>
          </ul>
          <div class="link-content">
            <transition>
              <ul v-show="linkActiveTab == 0" class="link-content-list">
                <li class="item-1-1">
                  <a @click="toguids('/solution', 'vision', 0)">
                    <div class="link-item-content">
                      <h4>作业人员保护</h4>
                      <p>全程实时作业人员跟踪分析、安全态势感知。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-1-2">
                  <a @click="toguids('/solution', 'vision', 1)">
                    <div class="link-item-content">
                      <h4>大车防撞</h4>
                      <p>融合视觉、激光雷达等多维数据保证安全。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-1-3">
                  <a @click="toguids('/solution', 'vision', 2)">
                    <div class="link-item-content">
                      <h4>锁销状态监测</h4>
                      <p>
                        对作业拆装锁状态进行监测，发现异常情况，及时联动作业控制信号。
                      </p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
              </ul>
            </transition>
            <transition>
              <ul v-show="linkActiveTab == 1" class="link-content-list">
                <li class="item-2-1">
                  <a @click="toguid('/solution', 'tally_a1')">
                    <div class="link-item-content">
                      <h4>箱号识别</h4>
                      <p>不需要集装箱停留状态下,识别精度高达99%。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-2-2">
                  <a @click="toguid('/solution', 'tally_a1')">
                    <div class="link-item-content">
                      <h4>集装箱验残</h4>
                      <p>能够识别毫米级的残损状态</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-2-3">
                  <a @click="toguid('/solution', 'tally_a2')">
                    <div class="link-item-content">
                      <h4>签封检测</h4>
                      <p>采用自建数据集，识别多种箱型铅封状态。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-2-4">
                  <a @click="toguid('/solution', 'tally_a2')">
                    <div class="link-item-content">
                      <h4>危险品标识检测</h4>
                      <p>对通用危险品标识和用户自定义标识进行检测。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
              </ul>
            </transition>
            <transition>
              <ul v-show="linkActiveTab == 2" class="link-content-list">
                <li class="item-3-1">
                  <a @click="toguid('/solution', 'court')">
                    <div class="link-item-content">
                      <h4>人员安全管理</h4>
                      <p>对场区内自主定义的功能区域，进行安全规范识别。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-3-2">
                  <a @click="toguid('/solution', 'court1')">
                    <div class="link-item-content">
                      <h4>设备安全管理</h4>
                      <p>AI联动全面提升场区内设备安全系数。</p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
                <li class="item-3-3">
                  <a @click="toguid('/solution', 'court2')">
                    <div class="link-item-content">
                      <h4>生产安全管理</h4>
                      <p>
                        场区生产管理系统深度融合，人员身份无感知查验，安全培训状态时刻掌控。
                      </p>
                    </div>
                    <i class="icon-more"></i>
                  </a>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
      <div class="welfare">
        <div class="welfare-carousel"></div>
        <div class="welfare-content">
          <h3>服务与支持</h3>
          <p>完善的售后服务系统与强有力的技术支持 <br />为您保驾护航</p>
          <a @click="toguid('/about')">
            <i></i>
          </a>
        </div>
      </div>
      <div class="develop">
          <div class="develop-inner">
            <div class="msg">
              <p>服务范围（部分）</p>
            </div>
            <div class="num">
              <div class="num-w">
                <p>全球23个</p>
                <div class="num-line"></div>
              </div>
              <div class="c_line">
              </div>
              <div class="num-w">
                <p class="opacty">国内13个</p>
                <div class="num-line opacty"></div>
              </div>
              <div class="smallmap"></div>
            </div>
          </div>
      </div>
      <div class="cooperation">
          <p class="title">商务合作</p>
          <div class="leftline"></div>
          <div class="rightline"></div>
          <div class="cooperation-banner"></div>
      </div>
    </main>
    <footer>
      <NavFooter></NavFooter>
    </footer>
    <Scroll></Scroll>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/navBar.vue";
import NavFooter from '../../components/Footer/footer.vue'
import Scroll from '../../components/scroll/scroll.vue'
export default {
  data() {
    return {
      status: "",
      linkActiveTab: "",
      welfareList: [
        {
          src: "https://img2.baidu.com/it/u=1913043963,3407437500&fm=26&fmt=auto&gp=0.jpg",
          desc: {
            title: "",
            content: "",
          },
        },
        {
          src: "https://img2.baidu.com/it/u=3175136295,4130758599&fm=26&fmt=auto&gp=0.jpg",
          desc: {
            title: "",
            content: "",
          },
        },
      ],
    };
  },
  methods: {
    tabActiveClass(index) {
      if (index == this.linkActiveTab) {
        return "link-tab-active";
      } else {
        return "";
      }
    },
    toguid(path, id) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
      // console.log(index)
      // this.$store.commit('homeStatute',index)
    },
    toguids(path, id, index) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
      console.log(index);
      this.$store.commit("homeStatute", index);
    },
    setActiveTab(index) {
      this.linkActiveTab = index;
    },
  },
  components: {
    NavBar,
    NavFooter,
    Scroll
  },
  mounted() {
    this.status = 0;
  },
};
</script>

<style lang='scss' scoped>
@import "./home.scss";
</style>