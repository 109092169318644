<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="more-info">
        <div class="nav">
          <div class="join-us" :class="{ 'nav-expand': joinUsExpand }">
            <div class="nav-title" @click="expandFooterNav(1)">
              <h3>关于我们</h3>
              <i></i>
            </div>
            <ul class="nav-list">
              <li class="w300">
                <a
                  >阅图智能创立于2020年，旨在通过AI技术为全球港口提供全面智慧化解决方案；不断提高港口生产效率和安全管理。</a
                >
              </li>
            </ul>
          </div>
          <div class="legal-info" :class="{ 'nav-expand': legalInfoExpand }">
            <div class="nav-title" @click="expandFooterNav(3)">
              <h3>网站相关</h3>
              <i></i>
            </div>
            <ul class="nav-list">
              <li>
                <router-link to="/center">产品中心</router-link>
              </li>
              <li @click="toguid('/about', 'address')">
                <a>联系我们</a>
              </li>
            </ul>
          </div>
          <div class="contact-us" :class="{ 'nav-expand': contactUsExpand }">
            <div class="nav-title" @click="expandFooterNav(2)">
              <h3>联系信息</h3>
              <i></i>
            </div>
            <ul class="nav-list">
              <li><a>上海市徐汇区南宁路1000号1205室</a></li>
              <li><a>021-54256965</a></li>
              <li><a>yuetu@yuetuai.com</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="outer-links">
        <a class="p1" href="https://beian.miit.gov.cn/"
          >沪ICP备2021015201号-1</a
        >
        <p class="p2">
          Copyright 2020 - 2021 Yuetu AI . All Rights Reserved . 阅图智能
          版权所有
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      joinUsExpand: false,
      contactUsExpand: false,
      legalInfoExpand: false,
    };
  },
  methods: {
    expandFooterNav(index) {
      if (index == 1) {
        this.joinUsExpand = !this.joinUsExpand;
      } else if (index == 2) {
        this.contactUsExpand = !this.contactUsExpand;
      } else if (index == 3) {
        this.legalInfoExpand = !this.legalInfoExpand;
      }
    },
    toguid(path, id) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
    },
  },
};
</script>

<style lang='scss' scoped>
.footer {
  @media screen and (max-width: 992px) {
    background-color: #fbfbfb;
  }
  .footer-inner {
    width: 1200px;
    margin: 0px auto;
    padding-top: 33px;
    padding-bottom: 44px;
    @media screen and (min-width: 992px) and (max-width: 1250px) {
      width: 96%;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 0px 30px 20px 30px;
    }
  }
  .more-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
      }
    }
  }

  .join-us,
  .contact-us,
  .legal-info {
    .nav-title {
      width: 100%;
      margin-bottom: 30px;
      h3 {
        color: #5f6464;
        font-size: 20px;
        @media screen and (max-width: 992px) {
          font-size: 16px;
        }
      }
      i {
        width: 12px;
        height: 12px;
        background-image: url("https://www.tencent.com/img/mobile/icon_arrow_d.png");
        background-size: 100% 100%;
        transition: all 0.2s;
      }

      @media screen and (max-width: 992px) {
        height: 52px;
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .nav-list {
      .w300 {
        width: 300px;
      }
      li {
        margin-bottom: 18px;

        a {
          font-size: 16px;
          font-weight: 400;
          color: #5f6464;
          line-height: 34px;
          cursor: pointer;
          @media screen and (max-width: 992px) {
            display: block;
            width: 100%;
            font-size: 14px;
            line-height: 30px;
          }
        }

        @media screen and (max-width: 992px) {
          margin-bottom: 0px;
        }
      }

      @media screen and (max-width: 992px) {
        height: 0px;
        transition: all 0.3s;
        overflow: hidden;
      }
    }

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .nav-expand {
    .nav-title {
      i {
        transform: rotate(180deg);
      }
    }

    .nav-list {
      height: 105px;
    }
  }

  .outer-links {
    width: 100%;
    margin-top: 48px;
    text-align: center;
    @media screen and (max-width: 992px) {
      margin-top: 18px;
    }
    .p1 {
      font-size: 14px;
      color: #57596a;
      text-align: center;
      margin-top: 10px;
      @media screen and (max-width: 992px) {
        color: #929797;
        font-size: 12px;
        line-height: 1.8;
      }
    }
    .p2 {
      font-size: 14px;
      color: #57596a;
      text-align: center;
      margin-top: 5px;
      @media screen and (max-width: 992px) {
        color: #929797;
        font-size: 12px;
        line-height: 1.8;
      }
    }
  }
}
</style>