<template>
  <div id="about">
    <nav :class="navClass">
      <div class="nav-inner">
        <div class="logo">
          <div class="logo-img"></div>
          <p>阅图智能</p>
        </div>
        <div class="action-box">
          <ul class="menu" v-for="(item, index) in navList" :key="index">
            <li class="menu-item" @click="handleClick(item.path)">
              <a href="javascript:">{{ item.name }}</a>
               <div class="line" v-if="item.status"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="menu-open-icon" @click="openMenuPanel"></div>
      <div
        v-show="menuPanelShow"
        class="menu-panel-mask"
        @click="closeMenuPanel"
      ></div>
      <transition @enter="menuPanelShowAnime" @leave="menuPanelHideAnime">
        <div v-show="menuPanelShow" class="menu-panel">
          <div class="menu-close-icon" @click="closeMenuPanel"></div>
          <ul class="panel-menu">
            <li>
              <router-link to="/home">首页</router-link>
            </li>
            <li>
              <router-link to="/solution">解决方案</router-link>
            </li>
            <li>
              <router-link to="/center">产品中心</router-link>
            </li>
            <li>
              <router-link to="/about">关于我们</router-link>
            </li>
          </ul>
        </div>
      </transition>
    </nav>
  </div>
</template>

<script>
import util from "../../assets/js/util";
export default {
  props: {
    message:{
      
    }
  },
  data() {
    return {
      expand: false,
      pageScroll: 0,
      menuPanelShow: false,
      scrollDirection: "down",
      navList: [
        {
          name: "首页",
          path: "/home",
          status: false,
        },
        {
          name: "解决方案",
          path: "/solution",
          status: false,
        },
        {
          name: "产品中心",
          path: "/center",
          status: false,
        },
        {
          name: "关于我们",
          path: "/about",
          status: false,
        },
      ],
    };
  },
  methods: {
    handleClick(path) {
      this.$router.push(path);
    },
    handleScroll() {
      window.addEventListener("scroll", (event) => {
        let scrollTop = util.getPageScrollY();
        if (scrollTop > this.pageScroll) {
          this.scrollDirection = "down";
        } else {
          this.scrollDirection = "up";
        }
        this.pageScroll = scrollTop;
      });
    },
    openMenuPanel() {
      this.menuPanelShow = true;
    },
    closeMenuPanel() {
      this.menuPanelShow = false;
    },
    menuPanelShowAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [-260, 0],
        easing: "linear",
        duration: 250,
        complete() {
          done();
        },
      });
    },
    menuPanelHideAnime(el, done) {
      this.anime({
        targets: el,
        translateX: [0, -260],
        easing: "linear",
        duration: 250,
        complete() {
          done();
        },
      });
    },
  },
  created() {
    this.handleScroll();
  },
  watch: {
    message: {
      handler(n) {
        this.navList[n].status = true;
      },
      deep: true,
    },
  },
  computed: {
    navClass() {
      if (this.pageScroll < 30) {
        if (this.expand) {
          return "white-nav white-nav-expand";
        } else {
          return "transparent-nav";
        }
      } else if (this.pageScroll >= 30 && this.pageScroll < 50) {
        return "white-nav";
      } else {
        if (this.scrollDirection == "down") {
          return "white-nav hide-nav";
        } else {
          return "white-nav";
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./navBar.scss";
</style>