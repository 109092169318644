<template>
  <div class="scrooTop" v-if="btnFlag" @click="backtop"></div>
</template>
<script>
export default {
  data() {
    return {
      btnFlag: false,
    };
  },
  methods: {
    backtop() {
      let that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 200) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang='scss' scoped>
.scrooTop {
  position: fixed;
  top: 750px;
  right: 50px;
  width: 69px;
  height: 98px;
  z-index: 999;
  cursor: pointer;
  background-image: url("../../assets/images/backtop.png");
  background-size: 100% 100%;
  @media screen and(max-width:992px) {
      display: none;
  }
  &:hover {
    position: fixed;
    top: 750px;
    right: 50px;
    width: 69px;
    height: 98px;
    z-index: 999;
    cursor: pointer;
    background-image: url("../../assets/images/backtopstatus.png");
    background-size: 100% 100%;
  }
}
</style>