import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css/animate.min.css'
import boot from './assets/js/boot'
boot.configAnime();
import './assets/css/global.css'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	ak: '8gW35xGung434iIl25Cruoi2WXoql8Sy'
  })
Vue.config.productionTip = false
router.afterEach(() => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
  })
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
